.mainBlocCommun {
  border-radius: 28px !important;
  background-color: white !important;
  margin-top: -10% !important;
  padding-top: 5% !important;
  margin-bottom: 30% !important;
}
.titleCommun {
  font-size: 30px;
  text-align: center;
  margin-top: 5px;
  font-family: "Montserrat", sans-serif;
  font-weight: bolder;
}
.communButton {
  background-color: #f5a624 !important;
  font-size: 18px;
  border-radius: 28px !important;
  border-color: #f5a624 !important;
}

.communButton:hover {
  background-color: #555 !important;
}

.communButton:active {
  background-color: #555 !important;
}

.communButton:visited {
  background-color: #555 !important;
}

.ButtonCol {
  text-align: center !important;
}
.mangoImgResp {
  height: 30px !important;
  width: 320px !important;
}
.marginInput {
  margin-bottom: 2%;
}

@media only screen and (min-device-width: 768px) {
  .mainBlocCommun {
    margin-left: auto !important;
    margin-right: auto !important;
    border-radius: 0px !important;
    background-color: white !important;
    margin-top: 0% !important;
    padding-top: 5% !important;
  }
  .formCommunGrp {
    padding-left: 30%;
    padding-right: 30%;
  }
  .titleCommun {
    font-size: 30px;
    text-align: center;
    font-family: "Montserrat", sans-serif;
  }
  .communButton {
    background-color: #f5a624 !important;
    font-size: 20px;
    border-radius: 28px !important;
    border-color: #f5a624 !important;
    text-align: center !important;
  }
  .communButton:hover {
    background-color: #555 !important;
    color: white !important;
    border-color: yellow !important;
  }
  .ButtonCol {
    text-align: center !important;
  }
  .mangoImgNormal {
    display: none !important;
  }
}
