body {
  background-color: #efefef;
}

.blocprincipalProfil {
  background: #ffffff;
  font-family: "Montserrat", sans-serif;
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 30px;
  border-radius: 28px;
  border: none;
  color: #555;

  box-shadow: 1px 1px 3px #999;
  padding: 7%;
  margin-top: -10%;
}
.infoProfil {
  text-align: center;
  margin-top: 10%;
  font-size: 26px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}
.form-control {
  padding: 1.375rem 0.75rem;
}

.userLogoProfil {
  color: #f4a521;
}
.buttontip {
  margin-top: -90px;
  margin-left: 170px;
  display: none;
}

.Titre-profil {
  color: #f4a521;
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}

.Bloc-principal .formBasicCheckbox {
  font-size: initial;
  color: white;
}
.buttondeco {
  margin-top: 10%;
  margin-bottom: 20px;
  background-color: #f5a624 !important;
  color: white;
  border-radius: 28px !important;
  border-color: #f5a624 !important;
  padding: 5%;
  width: 100%;
}
.buttondeco:hover {
  background-color: #555 !important;
  color: #f4a521 !important;
}

.buttonedit {
  margin-top: 10%;
  background-color: #f5a624 !important;
  color: white;
  border-radius: 28px !important;
  border-color: #f5a624 !important;
  padding: 5%;
  width: 100%;
}
.buttonedit:hover {
  background-color: #555 !important;
  color: #f4a521 !important;
}
.buttondelete {
  margin-top: 10%;
  background-color: #f5a624 !important;
  color: white;
  border-radius: 28px !important;
  border-color: #f5a624 !important;
  width: 100%;
  padding: 5%;
}

@media only screen and (min-device-width: 768px) {
  .blocprincipalProfil {
    margin-top: 0%;
    box-shadow: none;
    padding: 0% !important;
    margin-top: 2%;
  }
  .formProfil {
    width: 100%;
    margin-top: 4%;
  }
  .infoProfil {
    text-align: center;
    margin-top: 3% !important;
    font-size: 26px;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
  }
  .buttondelete {
    padding: 3% !important;
    width: 100% !important;
  }

  .Titre-profil {
    color: #f4a521;
    text-align: center;
    font-size: 28px;
    margin-top: 0% !important;
    font-weight: bold;
  }

  .buttondeco {
    padding: 3% !important;
    width: 100% !important;
  }

  .buttonedit {
    padding: 3% !important;
  }
}
