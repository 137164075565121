th{
    color: #f5a624;
}

.titleHisto{
    margin-top: 3%;
    margin-bottom: 3%;
}

.histoClient{
    margin-top: -10%;
    background-color: white;
    border-radius: 28px;
    padding-top: 5%;
}


@media (max-width: 700px){
    .table thead {
    display: none;
    }

    .table tr{
    display: block;
    margin-bottom: 40px;
    }

    .table td {
    display: block;
    text-align: right;
    }

    .table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    }
    }
    

@media only screen and (min-device-width: 768px) {
    .histoClient{
        margin-top: 0%;
        padding-bottom: 10%;
    }
}