.tipButton1 {
  background-color: #f5a624 !important;
  color: white;
  border-radius: 48px !important;
  border-color: #f5a624 !important;
  padding-bottom: 13%;
  padding-top: 13%;
  box-shadow: none;
  border-width: 0px;
}

.tipButton2 {
  background-color: #f5a624 !important;
  color: white;
  border-radius: 48px !important;
  border-color: #f5a624 !important;
  padding-bottom: 13%;
  padding-top: 13%;
  box-shadow: none;
  border-width: 0px;
}

.tipButton3 {
  background-color: #f5a624 !important;
  color: white;
  border-radius: 48px !important;
  border-color: #f5a624 !important;
  padding-bottom: 15%;
  padding-top: 15%;
  box-shadow: none;
  border-width: 0px;
}

.RowPourboire {
  margin-top: 5%;
}

.RowPourboire .col {
  text-align: center;
}
.titleTips {
  text-align: center;
  color: #f5a624;
  font-family: "Montserrat", sans-serif;
  margin-top: 5%;
}
.amount {
  text-align: center;
  color: #f5a624;
  font-family: "Montserrat", sans-serif;
  margin-top: 5%;
}

.validAmount {
  background-color: #f5a624 !important;
  color: white;
  border-radius: 28px !important;
  border-color: #f5a624 !important;
  padding: 3%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 35%;
}
.amountMsg {
  text-align: center;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  font-style: italic;
}

@media only screen and (min-device-width: 768px) {
  .allButton {
    text-align: center;
  }
  .containcheck {
    margin-left: auto !important;
  }

  .tipButton1 {
    padding-top: 2% !important;
    padding-bottom: 2% !important;
  }

  .tipButton2 {
    padding-top: 2% !important;
    padding-bottom: 2% !important;
  }

  .tipButton3 {
    padding-top: 2% !important;
    padding-bottom: 2% !important;
  }
  .tipButton2:hover {
    background-color: rgb(39, 38, 37) !important;
    color: white !important;
    border-color: yellow !important;
  }
  .tipButton1:hover {
    background-color: rgb(39, 38, 37) !important;
    color: white !important;
    border-color: yellow !important;
  }
  .tipButton3:hover {
    background-color: rgb(39, 38, 37) !important;
    color: white !important;
    border-color: yellow !important;
  }
  .validAmount {
    padding: 1%;
    margin-bottom: 10%;
  }
  .validAmount:hover {
    background-color: rgba(255, 102, 0, 0.774) !important;
    color: white !important;
    border-color: yellow !important;
  }
  .form-control {
    width: 100%;
    text-align: center;
  }
}
