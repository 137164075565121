.Titre {
  color: #f4a521;
  text-align: center;
  font-size: 18px;
}
.card {
  text-align: center;
}

.vousEtes {
  border-radius: 28px;
  width: 100%;
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
}

.hover:hover {
  background-color: #555 !important;
}
.hover:active {
  background-color: #555 !important;
}

.hover:visited {
  background-color: #555 !important;
}

.display {
  margin-bottom: 18%;
}
.vousEtes img {
  width: 10%;
  margin-bottom: 3%;
}

.colLieu {
  margin-top: 9%;
  margin-left: 10%;
}

.rowTitre {
  text-align: center;
  font-weight: bold !important;
  margin-top: 2% !important;
  font-size: 20px !important;
}

.rowTitre2 {
  text-align: center;
  margin-bottom: 2%;
  font-weight: bold !important;
  font-size: 20px !important;
}
.tipsTrueFalse {
  margin-bottom: 20%;
}

.general {
  text-align: center !important ;
  margin-bottom: 5%;
}

.rowImage {
  text-align: center;
}

.rowImage img {
  width: 80% !important;
  border-radius: 250px;
}

.blocPrincipalClient button {
  background-color: #f5a624 !important;
  color: white;
  border-radius: 28px !important;
  border-color: #f5a624 !important;
  margin-bottom: 20%;
}

.blocPrincipalClient {
  background: #ffffff;
  border-radius: 28px;
  border: none;
  margin-top: -19% !important;
  color: #555;
  box-shadow: 1px 1px 3px #999;
}

.butTips {
  text-align: center;
  margin-top: -2%;
  margin-bottom: 4%;
}

.colButton {
  text-align: center;
  margin-bottom: 5%;
}
@media only screen and (min-device-width: 768px) {
  .container {
    margin-right: auto;
  }
  .colButton:hover {
    text-align: center;
    margin-bottom: 5%;
  }
  .blocPrincipalClient {
    margin-top: 0% !important;
    box-shadow: none;
  }
  .giveTip {
    width: 26% !important;
    padding-bottom: 1%;
    padding-top: 1%;
  }

  .card-body {
    font-size: 45px;
  }
  .vousEtes {
    margin-top: 0%;
    margin-bottom: 5%;
    margin-right: 0% !important;
  }

  .vousEtes img {
    width: 5%;
  }
  .card {
    flex-direction: row !important;
  }

  .rowImage img {
    width: 58% !important;
  }

  .blocPrincipalClient button:hover {
    background-color: #555 !important;
    color: white !important;
    border-color: yellow !important;
  }

  .colLieu {
    margin-left: 0%;
  }
}
