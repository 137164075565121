.renewPass {
  background-color: #ffffff;
  border-radius: 28px;
  margin-top: -10%;
}

.formRenew input {
  border-radius: 10px;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  width: 74%;
}

.inputRenew {
  border-radius: 28px !important;
  width: 100% !important;
  padding: 12px 20px !important;
  margin: 8px 0 !important;
  box-sizing: border-box !important;
  margin-left: 1% !important;
  margin-right: 1% !important;
  border-width: 1px;
  border-style: inset;
  border-color: #80808057;
}

.renewButton {
  border-radius: 24px !important;
  background-color: #f5a624 !important;
  color: white !important;
  padding: 4%;
  font-size: 20px !important;
  font-family: "Montserrat", sans-serif !important;
  transition-duration: 0.4s !important;
  margin-left: 0% !important;
  border-color: #f5a624 !important;
  border-width: 0% !important;
}
.renewButton:hover {
  background-color: #f5a624;
  color: white;
}

.PassRenewCol {
  text-align: center;
}

.newPass {
  margin-top: 10%;
  margin-bottom: 5%;
  font-size: 20px;
}

@media only screen and (min-device-width: 1024px) {
  .renewPass {
    background-color: #ffffff;
    border-radius: 28px;
    margin-top: 0%;
    margin-right: auto !important;
    text-align: center;
  }
  .formRenew input {
    border-radius: 28px !important;
    width: 30% !important;
    padding: 12px 20px !important;
    margin: 8px 0 !important;
    box-sizing: border-box !important;
    margin-left: 1% !important;
    margin-right: 1% !important;
  }
  .renewButton {
    border-radius: 24px !important;
    background-color: #f5a624 !important;
    color: white !important;
    margin-bottom: 10%;
    font-size: 20px !important;
    font-family: "Montserrat", sans-serif !important;
    transition-duration: 0.4s !important;
    margin-left: 0% !important;
    border-color: #f5a624 !important;
    border-width: 0% !important;
    padding:1%!important
  }
  .renewButton:hover {
    background-color: #f5a624 !important;
    color: white !important;
  }
  .PassRenewDiv {
    margin-top: 200px !important;
  }
  .labelRenewEmail {
    font-size: 25px;
    font-family: "Montserrat", sans-serif !important;
    margin-left: 110px !important;
  }
  .labelRenewPass {
    font-size: 25px;
    font-family: "Montserrat", sans-serif !important;
  }

  .newPass {
    font-size: 28px;
  }
}
